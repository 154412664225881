/* ProductModal.css */
.custom-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1040;
  }
  
  .custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }
  
  .custom-modal-dialog {
    position: relative;
    width: auto;
    margin: 1.75rem auto;
    max-width: 1000px; /* Genişletilmiş modal */
    pointer-events: none;
  }
  
  .custom-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    border-radius: 0.3rem;
    outline: 0;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
  
  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #dee2e6;
  }
  
  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }
  
  /* Tablo stil güncellemeleri */
  .table img {
    transition: transform 0.2s;
  }
  
  .table img:hover {
    transform: scale(1.5);
  }
  
  .product-list-section {
    margin-top: 2rem;
  }