.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
}

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1055;
}

.custom-modal-dialog {
  width: 90%;
  max-width: 800px;
  margin: 1.75rem auto;
  position: relative;
}

.custom-modal-content {
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #dee2e6;
}

.modal-body {
  padding: 1rem;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.document-card {
    position: relative;
    border: 1px solid #dee2e6;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .document-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .document-label {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.9);
    padding: 8px 12px;
    font-size: 14px;
    border-top: 1px solid #dee2e6;
  }
  
  .document-card a {
    text-decoration: none;
    color: #333;
  }
  
  .document-card:hover {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  }